import React, { FC, useEffect, useRef } from 'react';
import { DynamicImage, PrimaryButton, Seo } from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import styled from 'styled-components';
import { usePageView, useQuizData } from 'utils/hooks';
import { tablet, useQuery } from 'styles/breakpoints';
import Disclaimer from 'components/Disclaimer';
import PaymentSteps from '../payments/components/PaymentSteps';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { resolveBookCoverName } from 'utils/hooks/resolveBookCoverName';
import quizSvgs from 'utils/constants/quizSvgs';
import CircleType from 'circletype';
import BirthChart from '../quiz/components/BirthChart';
import useQuizAnswersBirthDetails from 'utils/hooks/useQuizAnswersBirthDetails';
import { normalizeStates } from 'utils/localization';

const Container = styled.div`
  padding: 2rem 1rem;
  background: #f3f3f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 250px);
  @media ${tablet} {
    padding: 0rem 1rem 4rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  div#paper3 {
    position: absolute !important;
    top: 120px;
    right: 50%;
    transform: translateX(50%);
  }
  @media ${tablet} {
    div#paper3 {
      top: 70px;
    }
  }
`;

const Button = styled(PrimaryButton)`
  width: 100%;
  max-width: 28.75rem;
  margin: 0 auto;
`;

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.375rem 1rem 1.75rem;
  width: 100%;
  background-color: #f3f3f8;
  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;

const TextContainer = styled.div`
  max-width: 34.0635rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 0 2.375rem;

  @media ${tablet} {
    gap: 0.5rem;
    padding: 1.5rem 0 1.5rem;
  }
`;

const Title = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const Subtitle = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  max-width: 343px;
  width: 100% !important;
  @media ${tablet} {
    max-width: 220px;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 343px;
  background-size: contain;
  @media ${tablet} {
    width: 220px;
  }
`;

const BirthChartStyled = styled(BirthChart)`
  position: absolute !important;
  top: 0;
  div#paper {
    position: absolute !important;
    top: 0;
  }
`;

const SunSign = styled.div<{ color: string }>`
  display: flex;
  width: 46.773px;
  height: 46.773px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 59px;
  border: 0.532px solid ${({ color }) => color};
  position: absolute;
  bottom: 19px;
  left: 22px;
  gap: 2px;
  @media ${tablet} {
    width: 30px;
    height: 30px;
    bottom: 9px;
    left: 12px;
    gap: 1px;
  }
`;

const MoonSign = styled(SunSign)`
  bottom: 19px;
  right: 19px;
  left: unset;
  @media ${tablet} {
    bottom: 9px;
    right: 12px;
  }
`;
const SunText = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-size: 6px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: 'Montserrat', sans-serif;
  max-width: 30px;
  position: relative;
  text-transform: uppercase;
  @media ${tablet} {
    font-size: 4px;
    max-width: 19px;
  }
`;

const SvgSign = styled.div`
  width: 19px;
  height: 19px;
  position: relative;
  svg {
    position: absolute;
  }
  @media ${tablet} {
    width: 10px;
    height: 10px;
  }
`;

const Name = styled.p<{
  color: string;
  isSurname: boolean;
  isNameLongerThan10: boolean;
  isSurameLongerThan10: boolean;
}>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-family: 'Arno Pro';
  font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '17px' : '23px'};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '19px' : '23px'};
  letter-spacing: 2.5px;
  text-transform: uppercase;
  position: absolute;
  ${({ isSurname }) => !isSurname && 'top: 5.5rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
    isSurname && (isNameLongerThan10 || isSurameLongerThan10) && 'top: 4.9rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
    isSurname &&
    !(isNameLongerThan10 || isSurameLongerThan10) &&
    'top: 4.7rem;'}
 
  right: 50%;
  transform: translateX(50%);
  text-wrap: nowrap;
  @media ${tablet} {
    font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
      isNameLongerThan10 || isSurameLongerThan10 ? '10px' : '13px'};
    line-height: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
      isNameLongerThan10 || isSurameLongerThan10 ? '10px' : '13px'};
    top: ${({ isSurname }) => (isSurname ? '3rem' : '3.3rem')};
    letter-spacing: 2px;
  }
`;

const Date = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  font-family: 'Montserrat';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25rem; /* Reduced letter-spacing */
  position: absolute;
  bottom: 4.2rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 200px; /* Adjust the width as needed */
  text-transform: capitalize;
  @media ${tablet} {
    font-size: 6px;
    width: 100px;
    bottom: 2.85rem;
    letter-spacing: 0.2rem;
  }
`;

const City = styled(Date)<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  bottom: 3.2rem;
  @media ${tablet} {
    bottom: 2rem;
  }
`;

const Ebook = styled.p`
  position: absolute;
  bottom: -20px;
  right: 50%;
  transform: translateX(50%);
  color: #000;
  text-align: center;
  font-size: 1.94469rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0.22694rem 0.90788rem;
  border-radius: 0.52863rem;
  background: #f7c800;
  max-width: 7.76rem;
  @media ${tablet} {
    padding: 0.11844rem 0.47369rem;
    font-size: 1.01463rem;
    max-width: 4.07rem;
    bottom: -10px;
  }
`;

const ImageContainerPlus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  gap: 3.392rem;
  @media ${tablet} {
  }
`;

const DecoratePlusSign = styled.p`
  color: #f7c800;
  font-size: 40px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
`;

const BookContainer = styled.div`
  position: relative;
  max-width: 18.7rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  div#paper,
  div#paper2 {
    position: absolute !important;
    top: 39px;
    right: 50%;
    transform: translateX(50%);
  }
  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;

const BookCover = styled(DynamicImage)`
  width: 7.6875rem;
`;

const NamePlus = styled.p<{
  color: string;
  isSurname: boolean;
  isNameLongerThan10: boolean;
  isSurameLongerThan10: boolean;
}>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-family: 'Arno Pro';
  font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '6px' : '7px'};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '6.5px' : '7px'};
  letter-spacing: 0.6px;
  text-transform: uppercase;
  position: absolute;
  top: ${({ isSurname }) => (isSurname ? '1.65rem' : '1.85rem')};
  right: 50%;
  transform: translateX(50%);
  text-wrap: nowrap;
`;

const DatePlus = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  font-family: 'Montserrat';
  font-size: 3px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1rem;
  position: absolute;
  bottom: 1.45rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100px;
  text-transform: capitalize;
`;

const DatePlusEbook = styled(DatePlus)`
  bottom: 1.2rem;
`;

const CityPlus = styled(DatePlus)<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  bottom: 1.1rem;
`;

const CityPlusEbook = styled(CityPlus)`
  bottom: 0.9rem;
`;

const SunSignPlus = styled.div<{ color: string }>`
  display: flex;
  width: 17px;
  height: 17px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 23.386px;
  border: 0.532px solid ${({ color }) => color};
  position: absolute;
  bottom: 6px;
  left: 7px;
  gap: 1px;
`;

const SunTextPlus = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-size: 2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'Montserrat', sans-serif;
  max-width: 9px;
  position: relative;
  text-transform: uppercase;
`;

const MoonSignPlus = styled(SunSignPlus)`
  bottom: 6px;
  right: 8px;
  left: unset;
`;

const SvgSignPlus = styled.div`
  width: 6px;
  height: 6px;
  position: relative;
  svg {
    position: absolute;
  }
`;

const EBookCoverPlus = styled(DynamicImage)`
  width: 7.375rem;
`;

const EbookContainer = styled.div`
  display: flex;
  padding: 0.08556rem 0.34225rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -5px;
  border-radius: 0.17963rem;
  background: #f7c800;
  z-index: 3;
  right: 50%;
  transform: translateX(50%);
  color: #000;
  text-align: center;
  font-size: 0.6845rem;
  font-weight: 800;
`;

const BookPrepared: FC = () => {
  const { goToRegister } = useRouter();
  const quizData = useQuizData('bookPrepared');
  const { selected_plans, quiz_answers, geolocation, code, user } = useSelector(
    (state: AppState) => state.user,
  );
  const color = quiz_answers?.color;
  const contentColor = quiz_answers?.contentColor;
  const birthDetails = useQuizAnswersBirthDetails();
  const { isTablet } = useQuery();

  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    client_code: code,
    zipCode: quiz_answers?.shipping_address?.postcode?.toLowerCase(),
    phoneNumber: quiz_answers?.shipping_address?.phoneNumber,
  });

  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return <Svg />;
  };

  const textRefPlace = useRef<HTMLDivElement>(null);
  const textRefPlace2 = useRef<HTMLDivElement>(null);
  const textRefPlace3 = useRef<HTMLDivElement>(null);
  const textRefDate = useRef<HTMLDivElement>(null);
  const textRefDate2 = useRef<HTMLDivElement>(null);
  const textRefDate3 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRefPlace.current) {
      new CircleType(textRefPlace.current).dir(-1).radius(60);
    }
    if (textRefDate.current) {
      new CircleType(textRefDate.current).dir(-1).radius(40);
    }
    if (textRefPlace2.current) {
      new CircleType(textRefPlace2.current).dir(-1).radius(60);
    }
    if (textRefDate2.current) {
      new CircleType(textRefDate2.current).dir(-1).radius(40);
    }
    if (textRefPlace3.current) {
      new CircleType(textRefPlace3.current).dir(-1).radius(150);
    }
    if (textRefDate3.current) {
      new CircleType(textRefDate3.current).dir(-1).radius(120);
    }
  });

  useEffect(() => {
    setTimeout(
      () =>
        window.scrollTo({
          top: 1,
        }),
      100,
    );
  }, []);

  const ebookCheck = selected_plans
    ?.filter(item => !item.is_subscription)
    .some(item => item.type === 'digital');
  const paperBook = selected_plans
    ?.filter(item => !item.is_subscription)
    .some(item => item.type === 'physical');

  const resolvePageTextContent = () => {
    if (ebookCheck && !paperBook) {
      return {
        title: 'Your ebook is being prepared! 🎉',
        subtitle: 'It will be delivered to your email.',
      };
    }
    if (!ebookCheck && paperBook) {
      return {
        title: 'Your physical book is being prepared! 🎉',
        subtitle: 'It should arrive in 8-16 business days',
      };
    }
    return {
      title: 'Your physical & ebook are being prepared! 🎉',
      subtitle:
        'Your physical book should arrive in 8-16 business days and ebook will be sent to your email',
    };
  };

  const { title, subtitle } = resolvePageTextContent();

  const { isNameLongerThan10, isSurameLongerThan10, isSurname, formattedName } =
    resolveBookCoverName(quiz_answers?.name, quiz_answers?.surname);

  return (
    <>
      <Seo />
      <Header hasDivider={true} color="coldGrey" />
      <PaymentStepsContainer>
        <PaymentStepsStyled {...quizData?.paymentSteps} />
      </PaymentStepsContainer>
      <Container>
        {ebookCheck && paperBook ? (
          <ImageContainerPlus>
            <BookContainer>
              <BookCover
                alt="Nordastro cover"
                src={`books-mockups/${quiz_answers.color}.png`}
                // handleOnLoad={handleOnLoad}
              />
              <BirthChartStyled
                year={birthDetails?.year as number}
                month={birthDetails?.month as number}
                day={birthDetails?.day as number}
                hour={birthDetails?.hours as number}
                minute={birthDetails?.minutes as number}
                latitude={quiz_answers?.birth_place.lat}
                longitude={quiz_answers?.birth_place.lng}
                color={contentColor}
                width={90}
                height={90}
              />
              <NamePlus
                color={color}
                dangerouslySetInnerHTML={{ __html: formattedName }}
                isSurname={isSurname}
                isNameLongerThan10={isNameLongerThan10}
                isSurameLongerThan10={isSurameLongerThan10}
              />
              <DatePlus color={color} ref={textRefDate}>
                {birthDetails && birthDetails.fullDate}
              </DatePlus>

              <CityPlus color={color} ref={textRefPlace}>
                {`${quiz_answers?.birth_place.main_text}, ${quiz_answers?.birth_place.secondary_text}`}
              </CityPlus>
              <SunSignPlus color={contentColor}>
                <SunTextPlus color={color}>SUN SIGN</SunTextPlus>
                <SvgSignPlus>
                  {renderSvgImage(`${color}${quiz_answers?.zodiacSign}`)}
                </SvgSignPlus>
              </SunSignPlus>
              <MoonSignPlus color={contentColor}>
                <SunTextPlus color={color}>MOON SIGN</SunTextPlus>
                <SvgSignPlus>
                  {renderSvgImage(`${color}${quiz_answers?.planets.moon}`)}
                </SvgSignPlus>
              </MoonSignPlus>
              {/* {showName && <></>} */}
            </BookContainer>
            <DecoratePlusSign>+</DecoratePlusSign>
            <BookContainer>
              <EBookCoverPlus
                alt="Nordastro cover"
                src={`booksCovers/${quiz_answers.color}.png`}
                // handleOnLoad={handleOnLoad}
              />
              <BirthChartStyled
                year={birthDetails?.year as number}
                month={birthDetails?.month as number}
                day={birthDetails?.day as number}
                hour={birthDetails?.hours as number}
                minute={birthDetails?.minutes as number}
                latitude={quiz_answers?.birth_place.lat}
                longitude={quiz_answers?.birth_place.lng}
                color={contentColor}
                width={90}
                height={90}
                divId="paper2"
              />
              <NamePlus
                color={color}
                dangerouslySetInnerHTML={{ __html: formattedName }}
                isSurname={isSurname}
                isNameLongerThan10={isNameLongerThan10}
                isSurameLongerThan10={isSurameLongerThan10}
              />
              <DatePlusEbook color={color} ref={textRefDate2}>
                {birthDetails && birthDetails.fullDate}
              </DatePlusEbook>

              <CityPlusEbook color={color} ref={textRefPlace2}>
                {`${quiz_answers?.birth_place.main_text}, ${quiz_answers?.birth_place.secondary_text}`}
              </CityPlusEbook>
              <SunSignPlus color={contentColor}>
                <SunTextPlus color={color}>SUN SIGN</SunTextPlus>
                <SvgSignPlus>
                  {renderSvgImage(`${color}${quiz_answers?.zodiacSign}`)}
                </SvgSignPlus>
              </SunSignPlus>
              <MoonSignPlus color={contentColor}>
                <SunTextPlus color={color}>MOON SIGN</SunTextPlus>
                <SvgSignPlus>
                  {renderSvgImage(`${color}${quiz_answers?.planets.moon}`)}
                </SvgSignPlus>
              </MoonSignPlus>
              {/* {showName && (
              <> */}
              <EbookContainer>ebook</EbookContainer>
              {/* </>
            )} */}
            </BookContainer>
          </ImageContainerPlus>
        ) : (
          <ImageWrapper>
            <ImageContainer>
              <StyledDynamicImage
                src={`booksCovers/${color}.png`}
                alt=""
                // handleOnLoad={handleOnLoad}
              />
              <BirthChartStyled
                year={birthDetails?.year as number}
                month={birthDetails?.month as number}
                day={birthDetails?.day as number}
                hour={birthDetails?.hours as number}
                minute={birthDetails?.minutes as number}
                latitude={quiz_answers?.birth_place.lat}
                longitude={quiz_answers?.birth_place.lng}
                color={contentColor}
                width={isTablet ? 155 : 235}
                height={isTablet ? 155 : 235}
                divId="paper3"
              />
              <Name
                color={color}
                dangerouslySetInnerHTML={{ __html: formattedName }}
                isSurname={isSurname}
                isNameLongerThan10={isNameLongerThan10}
                isSurameLongerThan10={isSurameLongerThan10}
              />
              <Date color={color} ref={textRefDate3}>
                {birthDetails && birthDetails.fullDate}
              </Date>

              <City color={color} ref={textRefPlace3}>
                {`${quiz_answers?.birth_place.main_text}, ${quiz_answers?.birth_place.secondary_text}`}
              </City>
              <SunSign color={contentColor}>
                <SunText color={color}>SUN SIGN</SunText>
                <SvgSign>
                  {renderSvgImage(`${color}${quiz_answers?.zodiacSign}`)}
                </SvgSign>
              </SunSign>
              <MoonSign color={contentColor}>
                <SunText color={color}>MOON SIGN</SunText>
                <SvgSign>
                  {renderSvgImage(`${color}${quiz_answers?.planets.moon}`)}
                </SvgSign>
              </MoonSign>
            </ImageContainer>
            {ebookCheck && !paperBook && <Ebook>ebook</Ebook>}
          </ImageWrapper>
        )}
        <TextContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextContainer>
        <Button onClick={() => goToRegister()}>{quizData?.buttonText}</Button>
      </Container>

      <Disclaimer bgColor="white" text={quizData?.disclaimer} />
    </>
  );
};

export default BookPrepared;
